import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import React, { useState, useEffect } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import AdminService from "../../../components/services/admin.service";
import "../../../assets/styles/admin/datatable.css";
import { toast, ToastContainer } from "react-toastify";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import DelBin from "../../../assets/images/myregisteration/trash-fill.svg";
import { lStorage } from "../../../components/utils/storage";
import { navigate } from "gatsby";
import View from "../../../assets/images/successfulcards/view-icon.svg";

const POCRegTable = () => {
  const [adminUsers, setAdminUsers] = useState();
  const [selectedAdminUsers, setSelectedAdminUsers] = useState(null);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    mobile_number: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    country_id: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    state_id: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    city_id: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState();

  const adminServices = new AdminService();

  const getAdminUserlist = () => {
    adminServices.getAdminUserlist().then((data) => {
      setAdminUsers(data?.data?.data);
      console.log("Res", data?.data?.data);
    });
  };

  useEffect(() => {
    getAdminUserlist();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between align-items-center">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search"
          />
        </span>
        <span>
          <span className="mr-1">
            {/* <button class="btn btn-primary" onClick={() => navigate('/admin/approveCompany')}>
            View
          </button> */}
          </span>
          <a onClick={() => addPOC()} class="btn btn-primary">
            Add POC
          </a>
        </span>
      </div>
    );
  };

  const addPOC = () => {
    lStorage.set("selectPOCLocation", "");
    navigate("/admin/POC-Locations");
  };

  const header = renderHeader();

  return (
    <div className="datatable-doc-demo">
      <h3 className="py-3 px-2"><b>Registered POC</b></h3>
      <div className="card">
        <DataTable
          value={adminUsers}
          paginator
          className="p-datatable-customers"
          header={header}
          rows={15}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[15, 30, 50]}
          dataKey="admin_id"
          rowHover
          selection={selectedAdminUsers}
          onSelectionChange={(e) => setSelectedAdminUsers(e.value)}
          filters={filters}
          filterDisplay="menu"
          // loading={loading}
          responsiveLayout="scroll"
          stripedRows
          size="small"
          emptyMessage="No Records found."
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        >
          <Column
            field="mobile_number"
            header="POC Phone"
            sortable
            style={{ width: "8rem" }}
            filter
            filterPlaceholder="Search by Phone"
          />

          <Column
            field="country.country_name"
            header="Country"
            sortable
            style={{ width: "8rem" }}
            filter
            filterPlaceholder="Search by Country"
          />

          <Column
            field="state.state_name"
            header="State"
            sortable
            filter
            style={{ width: "5rem" }}
          />

          <Column
            field="city.city_name"
            header="City"
            sortable
            filter
            style={{ width: "5rem" }}
          />

        </DataTable>
      </div>
      <ToastContainer position="top-right" autoClose={3000} />
    </div>
  );
};

export default POCRegTable;
